<template>
  <div>
    <div
      v-if="loading"
      class="tex-center"
    >
      <strong>Talep Edilen Veriler Yükleniyor...</strong> <br> <br>
      <div
        class="spinner-border ms-auto text-success"
        role="status"
        aria-hidden="true"
      />
    </div>
    <div
      v-else
      class="row"
    >
      <div
        v-if="inventoryData"
        class="card"
      >
        <!-- Başlık -->
        <div class="row card-header mb-3">
          <div class="col">
            <h3 class="card-title">
              Tüm Stoklar
            </h3>
          </div>
        </div>
        <!-- Ürün Arama -->
        <div class="row mb-3">
          <div class="col">
            <div class="input-group">
              <input
                v-model="search"
                type="search"
                placeholder="Ürün Araması Yapınız..."
              >
              <span class="input-group-text">{{ searchFilter.length }}</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="col-md-12"
          >
            <table
              class="table table-striped table-hover table-bordered"
            >
              <thead
                class="text-white bg-danger thead"
              >
                <!-- Toplam Değerler -->
                <tr style="background-color: white; color: black;">
                  <th />
                  <th />
                  <th> {{ totalStocks.totalBaseStocks.toLocaleString() }} </th>
                  <!-- <th> {{ totalStocks.totalOnlineStocks.toLocaleString() }} </th> -->
                  <th> {{ totalStocks.totalMachineStocks.toLocaleString() }} </th>
                  <th> {{ totalStocks.totalTotalStocks.toLocaleString() }} </th>
                  <th> {{ totalOrders.totalOrdersWS.toLocaleString() }} </th>
                  <th> {{ totalOrders.totalOrdersS.toLocaleString() }} </th>
                  <th> {{ totalOrders.totalOnlineOrders.toLocaleString() }} </th>
                  <th> {{ totalOrders.totalOrders.toLocaleString() }} </th>
                  <th> {{ totalStocks.totalAvailableStocks.toLocaleString() }} </th>
                  <th> {{ totalOrders.totalTotalNeed.toLocaleString() }} </th>
                </tr>
                <tr>
                  <th @click="selectColumn('itemCode')">
                    {{ selectedColumn === "itemCode" ? (sortType === 'DESC' ?
                      'Ürün Kodu ↓' : 'Ürün Kodu ↑') : 'Ürün Kodu' }}
                  </th>
                  <th>
                    <a
                      class="nav-link"
                      href="#"
                    >Ürün Adı</a>
                  </th>
                  <th @click="selectColumn('baseStock')">
                    {{ selectedColumn === "baseStock" ? (sortType === 'DESC' ?
                      'Merkez Stok ↓' : 'Merkez Stok ↑') : 'Depo Stok' }}
                  </th>
                  <!-- <th @click="selectColumn('onlineStock')">
                    {{ selectedColumn === "onlineStock" ? (sortType === 'DESC' ?
                      'Online Stok ↓' : 'Online Stok ↑') : 'Online Stok' }}
                  </th> -->
                  <th @click="selectColumn('machineStock')">
                    {{ selectedColumn === "machineStock" ? (sortType === 'DESC' ?
                      'Makine Stok ↓' : 'Makine Stok ↑') : 'Makine Stok' }}
                  </th>
                  <th @click="selectColumn('totalStock')">
                    {{ selectedColumn === "totalStock" ? (sortType === 'DESC' ?
                      'Toplam Stok ↓' : 'Toplam Stok ↑') : 'Toplam Stok' }}
                  </th>
                  <th @click="selectColumn('orderWS')">
                    {{ selectedColumn === "orderWS" ? (sortType === 'DESC' ?
                      'Toptan Sipariş ↓' : 'Toptan Sipariş ↑') : 'Toptan Sipariş' }}
                  </th>
                  <!-- <th @click="selectColumn('reserveWS')">
                {{ selectedColumn === "reserveWS" ? (sortType === 'DESC' ?
                  'Toptan Rezerv ↓' : 'Toptan Rezerv ↑') : 'Toptan Rezerv' }}
              </th> -->
                  <th @click="selectColumn('orderS')">
                    {{ selectedColumn === "orderS" ? (sortType === 'DESC' ?
                      'Mağaza Sipariş ↓' : 'Mağaza Sipariş ↑') : 'Mağaza Sipariş' }}
                  </th>
                  <!-- <th @click="selectColumn('reserveS')">
                {{ selectedColumn === "reserveS" ? (sortType === 'DESC' ?
                  'Mağaza Rezerv ↓' : 'Mağaza Rezerv ↑') : 'Mağaza Rezerv' }}
              </th> -->
                  <!-- <th @click="selectColumn('minOnlineStock')">
                {{ selectedColumn === "minOnlineStock" ? (sortType === 'DESC' ?
                  'Min Online Stok ↓' : 'Min Online Stok ↑') : 'Min Online Stok' }}
              </th> -->
                  <th @click="selectColumn('onlineOrder')">
                    {{ selectedColumn === "onlineOrder" ? (sortType === 'DESC' ?
                      'Online Sipariş ↓' : 'Online Sipariş ↑') : 'Online Sipariş' }}
                  </th>
                  <th @click="selectColumn('totalOrder')">
                    {{ selectedColumn === "totalOrder" ? (sortType === 'DESC' ?
                      'Toplam Sipariş ↓' : 'Toplam Sipariş ↑') : 'Toplam Sipariş' }}
                  </th>
                  <th @click="selectColumn('totalAvailable')">
                    {{ selectedColumn === "totalAvailable" ? (sortType === 'DESC' ?
                      'Kull. Stok ↓' : 'Kull. Stok ↑') : 'Kull. Stok' }}
                  </th>
                  <th @click="selectColumn('totalNeed')">
                    {{ selectedColumn === "totalNeed" ? (sortType === 'DESC' ?
                      'Toplam İhtiyaç ↓' : 'Toplam İhtiyaç ↑') : 'Toplam İhtiyaç' }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="data in searchFilter"
                  :key="data.itemCode"
                >
                  <th>{{ data.itemCode }}</th>
                  <th>{{ data.itemName }}</th>
                  <th>{{ data.baseStock }}</th>
                  <!-- <th>{{ data.onlineStock }}</th> -->
                  <th>{{ data.machineStock }}</th>
                  <th>{{ data.totalStock }}</th>
                  <th>{{ data.orderWS }}</th>
                  <!-- <th>{{ data.reserveWS }}</th> -->
                  <th>{{ data.orderS }}</th>
                  <!-- <th>{{ data.reserveS }}</th> -->
                  <!-- <th>{{ data.minOnlineStock }}</th> -->
                  <th>{{ data.onlineOrder }}</th>
                  <th>{{ data.totalOrder }}</th>
                  <th>{{ data.totalAvailable }}</th>
                  <th>{{ data.totalNeed }}</th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AllStocks',
  data() {
    return {
      inventoryData: null,
      selectedColumn: 'itemCode',
      sortType: 'DESC',
      search: '',
      loading: true,
      totalStocks: {
        totalBaseStocks: 0,
        totalOnlineStocks: 0,
        totalTotalStocks: 0,
        totalAvailableStocks: 0,
      },
      totalOrders: {
        totalOrdersWS: 0,
        totalOrdersS: 0,
        totalOnlineOrders: 0,
        totalOrders: 0,
        totalTotalNeed: 0,
      },
    };
  },
  computed: {
    searchFilter() {
      this.clearFields();
      return this.inventoryData.filter((data) => {
        const searchLower = this.search.toLowerCase();
        if (
          data.itemName.toLowerCase().includes(searchLower)
        || data.itemCode.toLowerCase().includes(searchLower)
        ) {
          this.totalStocks.totalBaseStocks += data.baseStock;
          this.totalStocks.totalOnlineStocks += data.onlineStock;
          this.totalStocks.totalMachineStocks += data.machineStock;
          this.totalStocks.totalTotalStocks += data.totalStock;
          this.totalStocks.totalAvailableStocks += data.totalAvailable;
          this.totalOrders.totalOrdersWS += data.orderWS;
          this.totalOrders.totalOrdersS += data.orderS;
          this.totalOrders.totalOnlineOrders += data.onlineOrder;
          this.totalOrders.totalOrders += data.totalOrder;
          this.totalOrders.totalTotalNeed += data.totalNeed;
          return data;
        }
        return false;
      });
    },
  },

  mounted() {
    this.getAllStocks();
  },
  methods: {
    clearFields() {
      this.totalStocks.totalBaseStocks = 0;
      this.totalStocks.totalOnlineStocks = 0;
      this.totalStocks.totalMachineStocks = 0;
      this.totalStocks.totalTotalStocks = 0;
      this.totalStocks.totalAvailableStocks = 0;
      this.totalOrders.totalOrdersWS = 0;
      this.totalOrders.totalOrdersS = 0;
      this.totalOrders.totalOnlineOrders = 0;
      this.totalOrders.totalOrders = 0;
      this.totalOrders.totalTotalNeed = 0;
    },
    getAllStocks() {
      this.axios
        .get(`${this.baseUrl}/report/inventoryNeed`, this.$store.getters.authHeader)
        .then((response) => {
          this.inventoryData = response.data;
          this.loading = false;
        }).catch(() => {
          this.$router.push('/');
        });
    },
    selectColumn(columnName) {
      if (columnName === this.selectedColumn) {
        this.sortType = this.sortType === 'DESC' ? 'ASC' : 'DESC';
      } else {
        this.selectedColumn = columnName;
        this.sortType = 'DESC';
      }
      this.sortData();
    },
    sortData() {
      const multiplier = this.sortType === 'DESC' ? 1 : -1;

      if (this.selectedColumn === 'itemCode') {
        this.inventoryData.sort((x, y) => {
          if ((x.itemCode ?? 0) < (y.itemCode ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.itemCode ?? 0) > (y.itemCode ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'baseStock') {
        this.inventoryData.sort((x, y) => {
          if ((x.baseStock ?? 0) < (y.baseStock ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.baseStock ?? 0) > (y.baseStock ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'onlineStock') {
        this.inventoryData.sort((x, y) => {
          if ((x.onlineStock ?? 0) < (y.onlineStock ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.onlineStock ?? 0) > (y.onlineStock ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'machineStock') {
        this.inventoryData.sort((x, y) => {
          if ((x.machineStock ?? 0) < (y.machineStock ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.machineStock ?? 0) > (y.machineStock ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'totalStock') {
        this.inventoryData.sort((x, y) => {
          if ((x.totalStock ?? 0) < (y.totalStock ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.totalStock ?? 0) > (y.totalStock ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'orderWS') {
        this.inventoryData.sort((x, y) => {
          if ((x.orderWS ?? 0) < (y.orderWS ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.orderWS ?? 0) > (y.orderWS ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'reserveWS') {
        this.inventoryData.sort((x, y) => {
          if ((x.reserveWS ?? 0) < (y.reserveWS ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.reserveWS ?? 0) > (y.reserveWS ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'orderS') {
        this.inventoryData.sort((x, y) => {
          if ((x.orderS ?? 0) < (y.orderS ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.orderS ?? 0) > (y.orderS ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'reserveS') {
        this.inventoryData.sort((x, y) => {
          if ((x.reserveS ?? 0) < (y.reserveS ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.reserveS ?? 0) > (y.reserveS ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'minOnlineStock') {
        this.inventoryData.sort((x, y) => {
          if ((x.minOnlineStock ?? 0) < (y.minOnlineStock ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.minOnlineStock ?? 0) > (y.minOnlineStock ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'onlineOrder') {
        this.inventoryData.sort((x, y) => {
          if ((x.onlineOrder ?? 0) < (y.onlineOrder ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.onlineOrder ?? 0) > (y.onlineOrder ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'totalOrder') {
        this.inventoryData.sort((x, y) => {
          if ((x.totalOrder ?? 0) < (y.totalOrder ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.totalOrder ?? 0) > (y.totalOrder ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'totalAvailable') {
        this.inventoryData.sort((x, y) => {
          if ((x.totalAvailable ?? 0) < (y.totalAvailable ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.totalAvailable ?? 0) > (y.totalAvailable ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'totalNeed') {
        this.inventoryData.sort((x, y) => {
          if ((x.totalNeed ?? 0) < (y.totalNeed ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.totalNeed ?? 0) > (y.totalNeed ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      }
    },
  },
};
</script>
<style>
.thead {
  position: sticky;
  top: 0;
}
</style>
