<template>
  <div>
    <div class="row">
      <Nebim />
    </div>
  </div>
</template>

<script>
import Nebim from '../components/NebimControl/nebim.vue';

export default {
  name: 'Orders',
  components: {
    Nebim,
  },
  mounted() {
    this.auth();
  },
  methods: {
    auth() {
      this.axios.get(`${this.baseUrl}/dashboard/auth`, this.$store.getters.authHeader)
        .catch(() => {
          this.$router.push('/');
        });
    },
  },
};
</script>
