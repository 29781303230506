<template>
  <div>
    <div
      v-if="loading"
      class="tex-center"
    >
      <strong>Talep Edilen Veriler Yükleniyor...</strong> <br> <br>
      <div
        class="spinner-border ms-auto text-success"
        role="status"
        aria-hidden="true"
      />
    </div>
    <div v-else>
      <div class="row">
        <div
          v-if="inventoryData"
          class="card"
        >
          <!-- Başlık -->
          <div class="row card-header mb-3">
            <div class="col">
              <h3 class="card-title">
                Online Stoklar
              </h3>
            </div>
          </div>
          <!-- Ürün Arama -->
          <div class="row mb-3">
            <div class="col">
              <div class="input-group">
                <input
                  v-model="search"
                  type="search"
                  placeholder="Ürün Araması Yapınız..."
                >
                <span class="input-group-text">{{ searchFilter.length }}</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-md-12"
            >
              <table
                class="table table-striped table-hover table-bordered"
              >
                <thead
                  class="text-white bg-danger thead"
                >
                  <tr style="background-color: white; color: black;">
                    <th />
                    <th />
                    <th>{{ totalBaseStocks.toLocaleString() }}</th>
                    <th />
                    <th>{{ totalOnlineStocks.toLocaleString() }}</th>
                    <th>{{ totalOrders.toLocaleString() }}</th>
                    <th>{{ totalAvailableStocks.toLocaleString() }}</th>
                  </tr>
                  <tr>
                    <th @click="selectColumn('itemCode')">
                      {{ selectedColumn === "itemCode" ? (sortType === 'DESC' ?
                        'Ürün Kodu ↓' : 'Ürün Kodu ↑') : 'Ürün Kodu' }}
                    </th>
                    <th>
                      <a
                        class="nav-link"
                        href="#"
                      >Ürün Adı</a>
                    </th>
                    <th @click="selectColumn('baseStock')">
                      {{ selectedColumn === "baseStock" ? (sortType === 'DESC' ?
                        'Depo Stok ↓' : 'Depo Stok ↑') : 'Depo Stok' }}
                    </th>
                    <th>Min. Stok</th>
                    <th @click="selectColumn('currentStock')">
                      {{ selectedColumn === "currentStock" ? (sortType === 'DESC' ?
                        'Stok ↓' : 'Stok ↑') : 'Stok' }}
                    </th>
                    <th @click="selectColumn('currentOrder')">
                      {{ selectedColumn === "currentOrder" ? (sortType === 'DESC' ?
                        'Sipariş Adedi ↓' : 'Sipariş Adedi ↑') : 'Sipariş Adedi' }}
                    </th>
                    <th @click="selectColumn('remainingStock')">
                      {{ selectedColumn === "remainingStock" ? (sortType === 'DESC' ?
                        // eslint-disable-next-line max-len
                        'Kullanılabilir Stok ↓' : 'Kullanılabilir Stok ↑') : 'Kullanılabilir Stok' }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="data in searchFilter"
                    :key="data.itemCode"
                    :style="{'backgroundColor' : rowColor(data)}"
                  >
                    <th>{{ data.itemCode }}</th>
                    <th>{{ data.itemName }}</th>
                    <th>{{ data.baseStock }}</th>
                    <th>{{ data.minOnlineStock }}</th>
                    <th>{{ data.currentStock }}</th>
                    <th>{{ data.currentOrder }}</th>
                    <th>{{ data.remainingStock }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OnlineStocks',
  data() {
    return {
      inventoryData: null,
      selectedColumn: 'itemCode',
      sortType: 'DESC',
      search: '',
      loading: true,
      totalBaseStocks: 0,
      totalOnlineStocks: 0,
      totalOrders: 0,
      totalAvailableStocks: 0,
    };
  },
  computed: {
    searchFilter() {
      this.clearFields();
      return this.inventoryData.filter((data) => {
        const searchLower = this.search.toLowerCase();
        if (
          data.itemName.toLowerCase().includes(searchLower)
        || data.itemCode.toLowerCase().includes(searchLower)
        ) {
          this.totalBaseStocks += data.baseStock;
          this.totalOnlineStocks += data.currentStock;
          this.totalOrders += data.currentOrder;
          this.totalAvailableStocks += data.remainingStock;
          return data;
        }
        return false;
      });
    },
  },

  mounted() {
    this.getInventories();
  },
  methods: {
    clearFields() {
      this.totalBaseStocks = 0;
      this.totalOnlineStocks = 0;
      this.totalOrders = 0;
      this.totalAvailableStocks = 0;
    },
    getInventories() {
      this.axios
        .get(`${this.baseUrl}/report/onlineInventory`, this.$store.getters.authHeader)
        .then((response) => {
          this.inventoryData = response.data;
          this.loading = false;
        }).catch(() => {
          this.$router.push('/');
        });
    },
    selectColumn(columnName) {
      if (columnName === this.selectedColumn) {
        this.sortType = this.sortType === 'DESC' ? 'ASC' : 'DESC';
      } else {
        this.selectedColumn = columnName;
        this.sortType = 'DESC';
      }
      this.sortData();
    },
    sortData() {
      const multiplier = this.sortType === 'DESC' ? 1 : -1;

      if (this.selectedColumn === 'itemCode') {
        this.inventoryData.sort((x, y) => {
          if ((x.itemCode ?? 0) < (y.itemCode ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.itemCode ?? 0) > (y.itemCode ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'currentStock') {
        this.inventoryData.sort((x, y) => {
          if ((x.currentStock ?? 0) < (y.currentStock ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.currentStock ?? 0) > (y.currentStock ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'currentOrder') {
        this.inventoryData.sort((x, y) => {
          if ((x.currentOrder ?? 0) < (y.currentOrder ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.currentOrder ?? 0) > (y.currentOrder ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'remainingStock') {
        this.inventoryData.sort((x, y) => {
          if ((x.remainingStock ?? 0) < (y.remainingStock ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.remainingStock ?? 0) > (y.remainingStock ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else if (this.selectedColumn === 'baseStock') {
        this.inventoryData.sort((x, y) => {
          if ((x.baseStock ?? 0) < (y.baseStock ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.baseStock ?? 0) > (y.baseStock ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      }
    },
    rowColor(item) {
      const availableStock = item.currentStock - item.currentOrder;
      if (item.baseStock > 0 && availableStock < (item.minOnlineStock * 0.1)) {
        return '#f0183e';
      }
      if (item.currentStock <= 0 && item.baseStock > 0) {
        return '#89CFF0';
      }
      if (item.baseStock > 0
      && item.minOnlineStock > 0
      && item.currentStock > 0
      && item.currentStock < (item.minOnlineStock / 2)) {
        return '#f4a932';
      }
      return '';
    },
  },
};
</script>
<style>
  .widget {
    padding-bottom: 20pt;
  }
  .thead {
  position: sticky;
  top: 0;
}
  </style>
