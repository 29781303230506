<template>
  <div>
    <div
      v-if="loading"
      class="tex-center"
    >
      <strong>Talep Edilen Veriler Yükleniyor...</strong> <br> <br>
      <div
        class="spinner-border ms-auto text-success"
        role="status"
        aria-hidden="true"
      />
    </div>
    <div v-else>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <p class="card-title text-center">
                Süreç Takip Listesi
                <span class="badge bg-primary rounded-pill">{{ dataSize }}</span>
              </p>
            </div>
            <div class="card-body">
              <select
                v-model="selectedItem"
                class="form-select mb-3"
              >
                <option
                  v-for="option in options"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.title }}
                </option>
              </select>
              <div
                v-show="info"
                class="table"
              >
                <table class="table table-bordered table-hover table-striped">
                  <thead
                    id="tabloBaslik"
                    class="text-white bg-danger"
                  >
                    <th>Fatura No</th>
                    <th>Fatura Tarihi</th>
                    <th>Fatura Saati</th>
                    <th>E-Fatura No</th>
                    <th>E-Fatura Durum Kodu</th>
                    <th>Dahili Açıklama</th>
                    <th>Müşteri Kodu</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in info"
                      :key="item.invoiceNumber"
                    >
                      <td>{{ item.invoiceNumber }}</td>
                      <td>{{ item.invoiceDate }}</td>
                      <td>{{ item.invoiceTime }}</td>
                      <td>{{ item.eInvoiceNumber }}</td>
                      <td>{{ item.eInvoiceStatusCode }}</td>
                      <td>{{ item.internalDescription }}</td>
                      <td>{{ item.currAccCode }}</td>
                    <!-- <td><button class="btn btn-success">Sil</button></td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      options: [
        { title: 'None Faturalar', value: 'NoneInvoice' },
        { title: 'Hata Alan Faturalar', value: 'InvoiceError' },
        { title: 'Gönderilemeyen E-Faturalar', value: 'EInvoiceError' },
        { title: 'Gönderilemeyen E-İrsaliyeler', value: 'EShipmentError' },
      ],
      info: [],
      selectedItem: '',
      loading: true,
    };
  },
  computed: {
    dataSize() {
      return this.info.length;
    },
  },
  watch: {
    selectedItem() {
      this.getData();
      this.loading = true;
    },
  },
  created() {
    // eslint-disable-next-line no-lone-blocks
    {
      this.selectedItem = this.options[0].value;
      this.getData(this.selectedItem);
    }
  },
  methods: {
    getData() {
      axios
        .get(`${this.baseUrl}/public/erpCheck?reportType=${this.selectedItem}`, this.$store.getters.authHeader)
      // eslint-disable-next-line no-return-assign
        .then((res) => {
          this.info = res.data;
          this.loading = false;
        }).catch(() => {
          this.$router.push('/');
        });
    },
  },
};
</script>
  <style>
  #card {
  margin-top: 20px;
  margin-bottom: 20px
}
  </style>
