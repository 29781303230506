<template>
  <div>
    <div
      v-if="loading"
      class="loading-overlay"
    >
      <div class="loading-content">
        <strong>Talep Edilen Veriler Yükleniyor...</strong> <br> <br>
        <div
          class="spinner-border ms-auto text-success"
          role="status"
          aria-hidden="true"
        />
      </div>
    </div>
    <div
      v-else
      id="cirobaslik"
      class="row"
    >
      <div class="card">
        <div class="card-header row mb-3">
          <div class="col">
            <h3 class="card-title">
              Platform Satış Raporu
            </h3>
          </div>
          <div class="col-md-auto">
            <input
              id="tarihbaslama"
              v-model="TarihBaslama"
              type="date"
            >
          </div>
          <div class="col-md-auto">
            <input
              id="tarihbitis"
              v-model="TarihBitis"
              type="date"
            >
          </div>
        </div>
        <div
          v-show="platformResponse"
          class="table"
        >
          <table class="table table-bordered table-hover table-striped">
            <thead
              id="tabloBaslik"
              class="text-white bg-danger"
            >
              <tr>
                <th @click="sort('platform')">
                  Platform
                </th>
                <th @click="sort('orderCount')">
                  Sipariş Adedi
                </th>
                <th @click="sort('totalOrderAmount')">
                  Toplam Ciro
                </th>
                <th @click="sort('averageBasketAmount')">
                  Ortalama Sepet Tutarı
                </th>
                <th @click="sort('totalCost')">
                  Toplam Maliyet
                </th>
                <th>Kar Oranı</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in platformResponse"
                :key="item.platform"
                @click="select(item.platform)"
              >
                <td>{{ item.platform }}</td>
                <td>{{ item.orderCount }}</td>
                <td>
                  {{ item.totalOrderAmount.toLocaleString('tr-TR', {
                    style: 'currency',
                    currency: 'TRY'}).replace(',00', '') }}
                </td>
                <td>
                  {{ item.averageBasketAmount.toLocaleString('tr-TR', {
                    style: 'currency',
                    currency: 'TRY'}) }}
                </td>
                <td>
                  {{ item.totalCost.toLocaleString('tr-TR', {
                    style: 'currency',
                    currency: 'TRY'}).replace(',00', '') }}
                </td>
                <td>% {{ calculateProfit(item) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

const tarih = new Date();
const startDateGunlukFormat = tarih.toISOString().slice(0, 10);

const nextday = new Date();
nextday.setDate(nextday.getDate() + 1);

export default {
  data() {
    return {
      TarihBaslama: startDateGunlukFormat,
      TarihBitis: nextday.toISOString().slice(0, 10),
      platformResponse: [],
      loading: true,
      sortColumn: '', // Ekledik: Sıralama için kullanılacak sütunun başlangıç değeri boş
      sortDirection: 1, // Ekledik: Sıralama yönünün başlangıç değeri 1 (artan) olarak ayarlandı
    };
  },
  watch: {
    TarihBaslama() {
      this.auth();
      this.loading = true;
    },
    TarihBitis() {
      this.auth();
      this.loading = true;
    },
  },
  created() {
    this.auth();
  },
  methods: {
    auth() {
      this.axios.get(`${this.baseUrl}/public/platformOrderGiro`, {
        params: { startDate: this.TarihBaslama, endDate: this.TarihBitis },
      }, this.$store.getters.authHeader)
        .then((response) => {
          this.platformResponse = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.$router.push('/');
        });
    },
    select(id) {
      console.log(id);
    //   this.$router.push(`/platform/${id}`);
    },
    calculateProfit(item) {
      const profit = ((item.totalOrderAmount - item.totalCost) / item.totalOrderAmount) * 100;
      return profit.toFixed(2);
    },
    sort(column) {
      if (this.sortColumn === column) {
        this.sortDirection *= -1; // Sıralama yönünü tersine çevir
      } else {
        this.sortColumn = column;
        this.sortDirection = 1; // Sıralama yönünü artan olarak ayarla
      }
      // Verileri sırala
      this.platformResponse.sort((a, b) => {
        if (a[column] < b[column]) {
          return -1 * this.sortDirection;
        } if (a[column] > b[column]) {
          return 1 * this.sortDirection;
        }
        return 0;
      });
    },
  },
};
</script>

    <style>
    .card-title {
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 1.2;
      color: #212529;
    }

    #cirobaslik {
      font-weight: bold;
    }

    #tabloBaslik {
      text-transform: uppercase;
    }

    #tarihbaslama {
      width: 100%;
      border: 2px solid rgb(0, 0, 0);
      border-radius: 5px;
      padding: 7px;
      margin-bottom: 10px;
    }

    #tarihbitis {
      width: 100%;
      border: 2px solid rgb(0, 0, 0);
      border-radius: 5px;
      padding: 7px;
      margin-bottom: 10px;
    }

    #tarihbaslama:hover {
      border: 2px solid gray;
      background-color: #00FFC6;
    }

    #tarihbitis:hover {
      border: 2px solid gray;
      background-color: #FF5677;
    }
    </style>
