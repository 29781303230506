<template>
  <div>
    <!-- Loading Ekranı -->
    <div
      v-if="loading"
      class="tex-center"
    >
      <strong>Talep Edilen Veriler Yükleniyor...</strong> <br> <br>
      <div
        class="spinner-border ms-auto text-success"
        role="status"
        aria-hidden="true"
      />
    </div>
    <div v-else>
      <!-- Üst Bilgi Kartları -->
      <div
        v-if="widgetData"
        class="row mb-3"
      >
        <div class="col-md-4">
          <div class="card card-5">
            <h3>Güncel Sipariş</h3>
            <p>{{ widgetData.waitingOrderCount }}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-6">
            <h3>Sevk Sipariş</h3>
            <p>{{ widgetData.dailyShippedOrderCount }}</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card card-7">
            <h3>Günlük Sipariş</h3>
            <p>{{ widgetData.todaysOrderCount }}</p>
          </div>
        </div>
      <!-- <div class="col-md-2 widget">
        <div class="card">
          <div class="card-body">
            <h4>Bekleyen Ürün</h4>
            <h5>{{ widgetData.waitingProductCount }}</h5>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-md-2 widget">
        <div class="card">
          <div class="card-body">
            <h4>Bekleyen Ürün</h4>
            <h5>{{ widgetData.waitingProductCount }}</h5>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-md-2 widget">
        <div class="card">
          <div class="card-body">
            <h4>Günlük Ürün</h4>
            <h5>{{ widgetData.todaysProductCount }}</h5>
          </div>
        </div>
      </div> -->
      </div>
      <!-- Tablo Gösterimi -->
      <div class="row">
        <div
          v-if="orderSummaryData"
          class="col-md-4"
        >
          <table class="table table-striped">
            <thead class="text-white bg-danger">
              <tr>
                <th>Platform</th>
                <th>Bekleyen Adet</th>
                <th>Günlük Adet</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="summary in orderSummaryData"
                :key="summary.platform"
              >
                <th>{{ summary.platform }}</th>
                <th>{{ summary.waitingCount }}</th>
                <th>{{ summary.dailyCount }}</th>
              </tr>
            </tbody>
            <tfoot class="text-white bg-success">
              <tr>
                <th>TOPLAM</th>
                <th>{{ orderStatsSum.waitingCount }}</th>
                <th>{{ orderStatsSum.dailyCount }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div
          v-if="dailyPreparedOrderData"
          class="col-md-4"
        >
          <table class="table table-striped">
            <thead class="text-white bg-danger">
              <tr>
                <th>#</th>
                <th>Kullanıcı Adı</th>
                <th>Günlük Adet</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data,index) in dailyPreparedOrderData"
                :key="data.username"
              >
                <th>{{ index + 1 }}</th>
                <th>{{ data.username }}</th>
                <th>{{ data.count }}</th>
              </tr>
            </tbody>
            <tfoot class="text-white bg-success">
              <tr>
                <th>#</th>
                <th>TOPLAM</th>
                <th>{{ dailySum }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div
          v-if="monthlyPreparedOrderData"
          class="col-md-4"
        >
          <table class="table table-striped">
            <thead class="text-white bg-danger">
              <tr>
                <th>#</th>
                <th>Kullanıcı Adı</th>
                <th>Aylık Adet</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in monthlyPreparedOrderData"
                :key="data.username"
              >
                <th>{{ index + 1 }}</th>
                <th>{{ data.username }}</th>
                <th>{{ data.count }}</th>
              </tr>
            </tbody>
            <tfoot class="text-white bg-success">
              <tr>
                <th>#</th>
                <th>TOPLAM</th>
                <th>{{ monthlySum }}</th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Shipment',
  data() {
    return {
      widgetData: null,
      orderSummaryData: null,
      dailyPreparedOrderData: null,
      monthlyPreparedOrderData: null,
      loading: true,
    };
  },
  computed: {
    orderStatsSum() {
      const myObject = [];
      myObject.waitingCount = 0;
      myObject.dailyCount = 0;
      this.orderSummaryData.forEach((e) => {
        myObject.waitingCount += e.waitingCount;
        myObject.dailyCount += e.dailyCount;
      });
      return myObject;
    },
    dailySum() {
      let sum = 0;
      this.dailyPreparedOrderData.forEach((e) => {
        sum += e.count;
      });
      return sum;
    },
    monthlySum() {
      let sum = 0;
      this.monthlyPreparedOrderData.forEach((e) => {
        sum += e.count;
      });
      return sum;
    },
  },
  mounted() {
    this.getWidgetData();
    this.getOrderSummaryData();
    this.getDailyPreparedOrderData();
    this.getMonthlyPreparedOrderData();
  },
  methods: {
    getWidgetData() {
      this.axios
        .get(`${this.baseUrl}/dashboard/whWidget`, this.$store.getters.authHeader)
        .then((response) => {
          this.widgetData = response.data;
          this.loading = false;
        }).catch(() => {
          this.$router.push('/');
        });
    },
    getOrderSummaryData() {
      this.axios
        .get(`${this.baseUrl}/dashboard/orderSummary`, this.$store.getters.authHeader)
        .then((response) => {
          this.orderSummaryData = response.data;
          this.loading = false;
        }).catch(() => {
          this.$router.push('/');
        });
    },
    getDailyPreparedOrderData() {
      this.axios
        .get(`${this.baseUrl}/dashboard/dailyPreparedStats`, this.$store.getters.authHeader)
        .then((response) => {
          this.dailyPreparedOrderData = response.data;
          this.loading = false;
        }).catch(() => {
          this.$router.push('/');
        });
    },
    getMonthlyPreparedOrderData() {
      this.axios
        .get(`${this.baseUrl}/dashboard/monthlyPreparedStats`, this.$store.getters.authHeader)
        .then((response) => {
          this.monthlyPreparedOrderData = response.data;
          this.loading = false;
        }).catch(() => {
          this.$router.push('/');
        });
    },
  },
};
</script>

<style>
.widget {
  padding-bottom: 20pt;
}
.card-5{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  padding: 14px 80px 18px 36px;
  background-image: url(https://i.ibb.co/XFNdkWn/save-time-5351511.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}
.card-6{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  padding: 14px 80px 18px 36px;
  background-image: url(https://i.ibb.co/0s0qdCR/delivery-courier-3705083.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}
.card-7{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  padding: 14px 80px 18px 36px;
  background-image: url(https://i.ibb.co/th7JKCN/checklist-1519152.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}
</style>
