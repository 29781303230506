<template>
  <div>
    <div
      v-if="loading"
      class="tex-center"
    >
      <strong>Talep Edilen Veriler Yükleniyor...</strong> <br> <br>
      <div
        class="spinner-border ms-auto text-success"
        role="status"
        aria-hidden="true"
      />
    </div>
    <div
      v-else
      class="row"
    >
      <div class="card">
        <div
          v-if="platformResponse"
          class="col-md-12"
        >
          <div class="card-header row mb-3">
            <div class="col">
              <h3 class="card-title">
                Mağazalar
              </h3>
            </div>
            <!-- <div class="col-md-auto disable">
              <input
                id="tarihbaslama"
                v-model="TarihBaslama"
                type="date"
              >
            </div>
            <div class="col-md-auto">
              <input
                id="tarihbitis"
                v-model="TarihBitis"
                type="date"
              >
            </div> -->
          </div>
          <table class="table table-bordered table-hover table-striped">
            <thead
              id="tabloBaslik"
              class="text-white bg-danger"
            >
              <tr>
                <th @click="sort('storeName')">
                  Mağaza Adı
                </th>
                <th @click="sort('invoiceCount')">
                  Fiş Adedi
                </th>
                <th @click="sort('productCount')">
                  Ürün Adedi
                </th>
                <th @click="sort('averageBasketAmount')">
                  Ortalama Sepet Tutarı
                </th>
                <th @click="sort('averageProductCount')">
                  Ortalama Ürün Adedi
                </th>
                <th @click="sort('amount')">
                  Toplam Ciro
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in platformResponse"
                :key="item.packageNumber"
              >
                <th>{{ item.storeName }}</th>
                <th>{{ item.invoiceCount }}</th>
                <th>{{ item.productCount }}</th>
                <th>
                  {{ item.averageBasketAmount.toLocaleString('tr-TR', {
                    style: 'currency',
                    currency: 'TRY'}) }}
                </th>
                <th>{{ item.averageProductCount }}</th>
                <th>
                  {{ item.amount.toLocaleString('tr-TR', {
                    style: 'currency',
                    currency: 'TRY'}) }}
                </th>
              </tr>
            </tbody>
            <tfoot class="text-white bg-success">
              <tr>
                <th>TOPLAM</th>
                <th>{{ totalObject.orderCount }} Adet</th>
                <th>{{ totalObject.productCount }} Adet</th>
                <th>
                  {{ totalObject.avgAmount.toLocaleString('tr-TR', {
                    style: 'currency',
                    currency: 'TRY'}) }}
                </th>
                <th>{{ totalObject.avgProduct.toFixed(2) }} Adet</th>
                <th>
                  {{ totalObject.totalGiro.toLocaleString('tr-TR', {
                    style: 'currency',
                    currency: 'TRY'}) }}
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

const tarih = new Date();
const startDateGunlukFormat = tarih.toISOString().slice(0, 10);

const nextday = new Date();
nextday.setDate(nextday.getDate() + 1);

export default {
  name: 'TumMagazalar',
  data() {
    return {
      TarihBaslama: startDateGunlukFormat,
      TarihBitis: nextday.toISOString().slice(0, 10),
      platformResponse: null,
      loading: true,
      sortColumn: '', // Ekledik: Sıralama için kullanılacak sütunun başlangıç değeri boş
      sortDirection: 1, // Ekledik: Sıralama yönünün başlangıç değeri 1 (artan) olarak ayarlandı
    };
  },
  computed: {
    totalObject() {
      const myObject = [];
      myObject.orderCount = 0;
      myObject.productCount = 0;
      myObject.avgAmount = 0;
      myObject.avgProduct = 0;
      myObject.totalGiro = 0;
      this.platformResponse.forEach((e) => {
        myObject.orderCount += e.invoiceCount;
        myObject.productCount += e.productCount;
        myObject.totalGiro += e.amount;
      });
      myObject.avgAmount = myObject.totalGiro / myObject.orderCount;
      myObject.avgProduct = myObject.productCount / myObject.orderCount;
      return myObject;
    },
    totalOrderCount() {
      let sum = 0;
      this.platformResponse.forEach((e) => {
        sum += e.invoiceCount;
      });
      return sum;
    },
  },
  watch: {
    TarihBaslama() {
      this.auth();
    },
    TarihBitis() {
      this.auth();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.axios.get(`${this.baseUrl}/dashboard/storeGiro`,
        // {
        //   params: { startDate: this.TarihBaslama, endDate: this.TarihBitis },
        // },
        this.$store.getters.authHeader)
        .then((response) => {
          this.platformResponse = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.$router.push('/');
        });
    },
    sort(column) {
      if (this.sortColumn === column) {
        this.sortDirection *= -1; // Sıralama yönünü tersine çevir
      } else {
        this.sortColumn = column;
        this.sortDirection = 1; // Sıralama yönünü artan olarak ayarla
      }
      // Verileri sırala
      this.platformResponse.sort((a, b) => {
        if (a[column] < b[column]) {
          return -1 * this.sortDirection;
        } if (a[column] > b[column]) {
          return 1 * this.sortDirection;
        }
        return 0;
      });
    },
  },
};
</script>
