<template>
  <div>
    <div
      v-if="loading"
      class="tex-center"
    >
      <strong>Talep Edilen Veriler Yükleniyor...</strong> <br> <br>
      <div
        class="spinner-border ms-auto text-success"
        role="status"
        aria-hidden="true"
      />
    </div>
    <div v-else>
      <div class="row">
        <div
          v-if="stores && inventoryData"
          class="card"
        >
          <!-- Başlık -->
          <div class="row card-header mb-3">
            <div class="col">
              <h3 class="card-title">
                Genel Stoklar
              </h3>
            </div>
          </div>
          <!-- Ürün Arama -->
          <div class="row mb-3">
            <div class="col">
              <div class="input-group">
                <input
                  v-model="search"
                  type="search"
                  placeholder="Ürün Araması Yapınız..."
                >
                <span class="input-group-text">{{ searchFilter.length }}</span>
              </div>
            </div>
          </div>
          <!-- Ürün Listesi -->
          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered table-hover table-striped">
                <thead class="text-white bg-danger thead">
                  <!-- Toplam Değerler -->
                  <tr style="background-color: white; color: black;">
                    <th />
                    <th />
                    <th
                      v-for="store in stores"
                      :key="store.storeCode"
                    >
                      {{ storeTotals[store.storeCode].toLocaleString() }}
                    </th>
                    <th> {{ totaltotalQuantity.toLocaleString() }}</th>
                  </tr>
                  <tr>
                    <th @click="sort('productCode')">
                      Ürün Kodu
                    </th>
                    <th>
                      <a
                        class="nav-link"
                        href="#"
                      >Ürün Adı</a>
                    </th>
                    <th
                      v-for="store in stores"
                      :key="store.storeCode"
                      @click="selectStore(store.storeCode)"
                    >
                      {{ selectedStore === store.storeCode ? (sortType === 'DESC' ?
                        (store.storeName + ' ↓') :(store.storeName + ' ↑') ) : store.storeName }}
                    </th>
                    <th
                      class="bg-success"
                      @click="selectStore('TOPLAM')"
                    >
                      {{ selectedStore === 'TOPLAM' ? (sortType === 'DESC' ?
                        ('TOPLAM' + ' ↓') :('TOPLAM' + ' ↑') ) : 'TOPLAM' }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="data in searchFilter"
                    :key="data.productCode"
                  >
                    <th>{{ data.productCode }}</th>
                    <th>{{ data.productName }}</th>
                    <th
                      v-for="store in stores"
                      :key="store.storeCode"
                    >
                      {{ data.stocks[store.storeCode] ?? 0 }}
                    </th>
                    <th>{{ data.totalQuantity }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Stocks',
  data() {
    return {
      stores: null,
      inventoryData: null,
      selectedStore: 'M',
      sortType: 'DESC',
      search: '',
      loading: true,
      sortColumn: '',
      sortDirection: 1,
      totaltotalQuantity: 0,
    };
  },
  computed: {
    searchFilter() {
      this.clearFields();
      return this.inventoryData.filter((data) => {
        const searchLower = this.search.toLowerCase();
        if (
          data.productName.toLowerCase().includes(searchLower)
        || data.productCode.toLowerCase().includes(searchLower)
        ) {
          this.totaltotalQuantity += data.totalQuantity;
          return data;
        }
        return false;
      });
    },
    // Her mağaza için ayrı ayrı toplam hesapla
    storeTotals() {
      const totals = {};

      if (this.stores && this.inventoryData) {
        // eslint-disable-next-line no-restricted-syntax
        for (const store of this.stores) {
          totals[store.storeCode] = this.calculateStoreTotal(store.storeCode);
        }
      }

      return totals;
    },
  },

  mounted() {
    this.getStoreList();
  },
  methods: {
    clearFields() {
      this.totaltotalQuantity = 0;
    },
    calculateStoreTotal(storeCode) {
      let total = 0;

      // eslint-disable-next-line no-restricted-syntax
      for (const data of this.searchFilter) {
        const stock = data.stocks[storeCode] ?? 0;
        total += stock;
      }

      return total;
    },
    getStoreList() {
      this.axios
        .get(`${this.baseUrl}/report/stores`, this.$store.getters.authHeader)
        .then((response) => {
          this.stores = response.data;
          this.getInventories();
        }).catch(() => {
          this.$router.push('/');
        });
    },
    getInventories() {
      this.axios
        .get(`${this.baseUrl}/report/allInventory`, this.$store.getters.authHeader)
        .then((response) => {
          this.inventoryData = response.data;
          this.sortData();
          this.loading = false;
        });
    },
    selectStore(storeCode) {
      if (storeCode === this.selectedStore) {
        this.sortType = this.sortType === 'DESC' ? 'ASC' : 'DESC';
      } else {
        this.selectedStore = storeCode;
        this.sortType = 'DESC';
      }
      this.sortData();
    },
    selectColumn(columnName) {
      if (columnName === this.selectedColumn) {
        this.sortType = this.sortType === 'DESC' ? 'ASC' : 'DESC';
      } else {
        this.selectedColumn = columnName;
        this.sortType = 'DESC';
      }
      this.sortData();
    },
    sortData() {
      const multiplier = this.sortType === 'DESC' ? 1 : -1;

      if (this.selectedStore === 'TOPLAM') {
        this.inventoryData.sort((x, y) => {
          if ((x.totalQuantity ?? 0) < (y.totalQuantity ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.totalQuantity ?? 0) > (y.totalQuantity ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      } else {
        this.inventoryData.sort((x, y) => {
          if ((x.stocks[this.selectedStore] ?? 0) < (y.stocks[this.selectedStore] ?? 0)) {
            return 1 * multiplier;
          }
          if ((x.stocks[this.selectedStore] ?? 0) > (y.stocks[this.selectedStore] ?? 0)) {
            return -1 * multiplier;
          }
          return 0;
        });
      }
    },
    sort(column) {
      if (this.sortColumn === column) {
        this.sortDirection *= -1; // Sıralama yönünü tersine çevir
      } else {
        this.sortColumn = column;
        this.sortDirection = 1; // Sıralama yönünü artan olarak ayarla
      }
      // Verileri sırala
      this.inventoryData.sort((a, b) => {
        if (a[column] < b[column]) {
          return -1 * this.sortDirection;
        } if (a[column] > b[column]) {
          return 1 * this.sortDirection;
        }
        return 0;
      });
    },
  },
};
</script>

  <style>
  .thead {
  position: sticky;
  top: 0;
}
.widget {
  padding-bottom: 20pt;
}
</style>
