<template>
  <div>
    <sidebar v-show="sidebarVisible" />
    <router-view />
  </div>
</template>

<script>
import Sidebar from './views/Sidebar.vue';

export default {
  name: 'App',
  components: { Sidebar },
  data() {
    return {
      sidebarVisible: true,
    };
  },
  created() {
    // localStorage'da saklanan sidebarVisible değeri varsa onu kullan
    const storedSidebarVisible = localStorage.getItem('sidebarVisible');
    if (storedSidebarVisible !== null) {
      this.sidebarVisible = JSON.parse(storedSidebarVisible);
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 1%;
}
</style>
