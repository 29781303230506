<template>
  <div class="col-sm-12">
    <iframe
      src="https://player.vimeo.com/video/858925142?h=528efef94c&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&muted=1"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      style="position:absolute;top:0;left:0;width:100%;height:100%;"
      title="Deneme Video"
    />
  </div>
</template>
<script>
export default {
  name: 'VideoExampleMuted',
  data() {
    return {

    };
  },
};
</script>
