<template>
  <nav class="navbar navbar-vertical bg-body-tertiary mb-3">
    <div class="container-fluid">
      <button
        id="offcanvasNavbarButton"
        class="btn btn-outline-success"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <h1 class="col navbar-header text-center">
        PROCSIN Herbal Science Technology
      </h1>
      <div
        id="offcanvasNavbar"
        class="offcanvas offcanvas-start"
        tabindex="-1"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <!-- Başlık -->
        <div class="offcanvas-header">
          <img
            id="offcanvasNavbarLabel"
            class="offcanvas-title"
            src="prslogo.png"
            alt="prslogo.png"
          >
          <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          /> -->
        </div>
        <!-- Menü -->
        <div class="offcanvas-body">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link
                class="nav-link"
                aria-current="page"
                to="/home"
              >
                Anasayfa
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/shipment"
              >
                Sevkiyat
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/products"
              >
                Ürünler
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link"
                to="/orders"
              >
                Nebim
              </router-link>
            </li>
            <!-- Satış Takibi -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Satış Takibi
              </a>
              <ul class="dropdown-menu">
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/products-by-date"
                  >
                    Günlük Satış Adetleri
                  </router-link>
                </li>
                <hr class="dropdown-divider">
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/waiting-products"
                  >
                    Bekleyen Ürünler
                  </router-link>
                </li>
                <hr class="dropdown-divider">
                <!-- <li>
                  <router-link
                    class="dropdown-item"
                    to="/platforms"
                  >
                    Platform Satışları
                  </router-link>
                </li>
                <hr class="dropdown-divider">
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/stores"
                  >
                    Mağaza Satışları
                  </router-link>
                </li> -->
              </ul>
            </li>
            <!-- Stok Takibi -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Stok Takibi
              </a>
              <ul class="dropdown-menu">
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/allStocks"
                  >
                    Bütün Stok Listesi
                  </router-link>
                </li>
                <hr class="dropdown-divider">
                <li>
                  <router-link
                    class="dropdown-item"
                    to="/stocks"
                  >
                    Mağazalar + Depo
                  </router-link>
                </li>
                <hr class="dropdown-divider">
                <!-- <li>
                  <router-link
                    class="dropdown-item"
                    to="/onlineStocks"
                  >
                    Online Stoklar
                  </router-link>
                </li> -->
              </ul>
            </li>
          </ul>
        </div>
        <!-- Footer -->
        <div class="offcanvas-footer mb-3">
          <button
            type="button"
            class="btn btn-outline-danger w-100"
            data-bs-dismiss="offcanvas"
            @click="logout"
          >
            Güvenli Çıkış
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
// const tarih = new Date();
// const gun = tarih.getDate();
// const ay = tarih.getMonth() + 1; // Ay değeri 0-11 arasında olduğu için 1 ekliyoruz.
// const yil = tarih.getFullYear();

// const formatliTarih = `${gun < 10 ? `0${gun}` : gun}.${ay < 10 ? `0${ay}` : ay}.${yil}`;
export default {
  name: 'Sidebar',
  data() {
    return {
      // startDateGunlukFormat: formatliTarih,
    };
  },

  methods: {
    logout() {
      this.$root.sidebarVisible = !this.$root.sidebarVisible;
      localStorage.setItem('sidebarVisible', JSON.stringify(this.$root.sidebarVisible));
      this.$store.commit('setToken', { value: null });
      this.$router.push('/');
    },
  },

};
</script>

<style>
.navbar {
  /* background-color: #e3f2fd; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  padding: 0 20px;
}
#offcanvasNavbar {
  width: 320px;
  background-color: #e3f2fd;
  text-align: center;
}
#offcanvasNavbar .offcanvas-header .offcanvas-title {
  width: 100%;
  height: 100%;
  padding: 10px;
}
#offcanvasNavbarButton {
    width: auto;
    height: auto;
  }
.nav-link.router-link-exact-active {
  color: green !important;
  font-weight: bold;
}

</style>
