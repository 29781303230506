<template>
  <div>
    <div
      v-if="loading"
      class="tex-center"
    >
      <strong>Talep Edilen Veriler Yükleniyor...</strong> <br> <br>
      <div
        class="spinner-border ms-auto text-success"
        role="status"
        aria-hidden="true"
      />
    </div>
    <div v-else>
      <div class="row">
        <div class="card">
          <div class="row card-header mb-3">
            <div class="col">
              <h3 class="card-title">
                Bekleyen Ürünler Listesi
              </h3>
            </div>
          </div>
          <!-- Excel + Source -->
          <div class="row">
            <div class="col mb-3 input-group">
              <input
                v-model="search"
                type="search"
                placeholder="Ürün Araması Yapınız..."
              >
              <span class="input-group-text">{{ searchFilter.length }}</span>
            </div>
            <div class="col-md-9 text-end">
              <button
                v-if="waitingProductsWithoutSet"
                class="btn btn-block btn-success "
              >
                <export-excel
                  :data="waitingProductsWithoutSet"
                  :name="`Bekleyen Ürünler Listesi`"
                >
                  Excel indir
                </export-excel>
              </button>
            </div>
          </div>
          <div class="row">
            <div
              v-if="waitingProducts"
              class="col-md-12"
            >
              <table class="table table-bordered table-hover table-striped">
                <thead class="text-white bg-danger thead">
                  <tr>
                    <th>Ürün Kodu</th>
                    <th>
                      <a
                        class="nav-link"
                        href="#"
                      >Ürün Adı</a>
                    </th>
                    <th>Bekleyen Adet</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="product in searchFilter"
                    :key="product.productCode"
                  >
                    <th>{{ product.productCode }}</th>
                    <th>{{ product.productName }}</th>
                    <th>{{ product.quantity }}</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaitingProducts',
  data() {
    return {
      soldProducts: null,
      setProductDetails: null,
      waitingProducts: null,
      search: '',
      loading: true,
    };
  },
  computed: {
    waitingProductsWithoutSet() {
      if (this.waitingProducts === null) {
        return null;
      }
      let temp = this.waitingProducts.slice();
      temp.forEach((element) => {
        const setArray = this.findSetProduct(element.productCode);
        if (setArray != null) {
          setArray.forEach((e) => {
            let found = false;
            temp.forEach((productObj) => {
              if (productObj.productCode === e.innerProductCode) {
                // eslint-disable-next-line no-param-reassign
                productObj.quantity += e.innerProductQuantity * element.quantity;
                found = true;
              }
              temp = temp.filter((item) => item !== element);
            });
            if (!found) {
              temp.push({ productCode: e.innerProductCode, productName: '-', quantity: element.quantity * e.innerProductQuantity });
            }
          });
        }
      });
      this.sortArray(temp);
      return temp;
    },
    searchFilter() {
      if (this.search === '') {
        return this.waitingProducts;
      }
      const searchLower = this.search.toLowerCase();
      return this.waitingProducts.filter((product) => {
        if (
          product.productName.toLowerCase().includes(searchLower)
        || product.productCode.toLowerCase().includes(searchLower)
        ) {
          return product;
        }
        return false;
      });
    },

  },
  mounted() {
    this.getSetProductDetails();
    this.getWaitingProducts();
  },
  methods: {
    getSetProductDetails() {
      this.axios
        .get(`${this.baseUrl}/dashboard/setProductDetails`, this.$store.getters.authHeader)
        .then((response) => {
          this.setProductDetails = response.data;
        }).catch(() => {
          this.$router.push('/');
        });
    },
    getWaitingProducts() {
      this.axios
        .get(`${this.baseUrl}/dashboard/waitingProducts`, this.$store.getters.authHeader)
        .then((response) => {
          this.waitingProducts = response.data;
          this.loading = false;
        }).catch(() => {
          this.$router.push('/');
        });
    },
    findSetProduct(productCode) {
      const temp = [];
      this.setProductDetails.forEach((element) => {
        if (element.setProductCode === productCode) {
          temp.push(element);
        }
      });
      return temp;
    },
    sortArray(array) {
      array.sort((x, y) => {
        if (x.quantity < y.quantity) {
          return 1;
        }
        if (x.quantity > y.quantity) {
          return -1;
        }
        return 0;
      });
    },
  },
};
</script>

<style>
.thead {
  position: sticky;
  top: 0;
}
</style>
