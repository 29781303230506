import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  plugins: [createPersistedState()],
  state() {
    return {
      counter: 0,
      token: '',
    };
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload.value;
    },
  },
  getters: {
    authHeader(state) {
      return {
        headers: { Authorization: `Bearer ${state.token}` },
      };
    },
  },
  actions: {
  },
  modules: {
  },
});
