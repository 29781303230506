import { createApp } from 'vue';
import excel from 'vue-excel-export';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

const app = createApp(App).use(store).use(router).use(excel);
app.config.globalProperties.axios = axios;
// app.config.globalProperties.baseUrl = 'http://176.236.208.115:13242';
app.config.globalProperties.baseUrl = 'https://report.procsiner.com';
// app.config.globalProperties.baseUrl = 'http://localhost:13242';
app.mount('#app');
