<template>
  <div>
    <!-- Loading Ekranı -->
    <div
      v-if="loading"
      class="tex-center"
    >
      <strong>Talep Edilen Veriler Yükleniyor...</strong> <br> <br>
      <div
        class="spinner-border ms-auto text-success"
        role="status"
        aria-hidden="true"
      />
    </div>
    <!-- Loading Ekranı Veriyi Alınca -->
    <div v-else>
      <div class="row mb-3">
        <div class="col-md-3">
          <div class="card card-1">
            <h3>Toplam Sipariş Adedi</h3>
            <p>{{ totalProcuctCount }}</p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card card-2">
            <h3>Ortalama Birim Fiyatı</h3>
            <p>
              {{ totalAveragePrice }}
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card card-3">
            <h3>Toplam Ciro</h3>
            <p>
              {{ totalAmount }}
            </p>
          </div>
        </div>
        <div class="col-md-3">
          <div class="card card-4">
            <h3>Toplam Maliyet</h3>
            <p>
              {{ totalCost }}
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <!-- Başlık Kısmı -->
          <div class="row card-header mb-3">
            <div class="col">
              <h3 class="card-title">
                Günlük Satış Listesi
              </h3>
            </div>
            <div class="col-md-auto">
              <input
                id="tarihbaslama"
                v-model="TarihBaslama"
                type="date"
              >
            </div>
            <div class="col-md-auto">
              <input
                id="tarihbitis"
                v-model="TarihBitis"
                type="date"
              >
            </div>
          </div>
          <!-- Excel + Source -->
          <div class="row">
            <div class="col mb-3 input-group">
              <input
                v-model="search"
                type="search"
                placeholder="Ürün Araması Yapınız..."
              >
              <span class="input-group-text">{{ searchFilter.length }}</span>
            </div>
            <div class="col-md-3 text-end">
              <button
                v-if="waitingProductsWithoutSet"
                class="btn btn-block btn-success "
              >
                <export-excel
                  :data="waitingProductsWithoutSet"
                  :name="`Günlük Satış Listesi ${TarihBaslama} - ${TarihBitis}`"
                >
                  Excel indir
                </export-excel>
              </button>
            </div>
          </div>
          <!-- Tablo -->
          <div class="row">
            <div
              v-show="waitingProducts"
              class="col-md-12"
            >
              <table class="table table-bordered table-hover table-striped">
                <thead
                  id="tabloBaslik"
                  class="text-white bg-danger thead"
                >
                  <tr>
                    <th>
                      <a
                        class="nav-link"
                        href="#"
                      >Ürün Adı</a>
                    </th>
                    <th @click="sort('totalProductCount')">
                      Sipariş Adedi
                    </th>
                    <th @click="sort('totalProductAmount')">
                      Toplam Ciro
                    </th>
                    <th @click="sort('averagePrice')">
                      Ortalama Birim Fiyatı
                    </th>
                    <th @click="sort('totalCost')">
                      Toplam Maliyet
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in searchFilter"
                    :key="item.productCode"
                  >
                    <td>{{ item.itemName }}</td>
                    <td>{{ item.totalProductCount }}</td>
                    <td>
                      {{ item.totalProductAmount.toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'TRY'}).replace(',00', '') }}
                    </td>
                    <td>
                      {{ item.averagePrice.toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'TRY'}) }}
                    </td>
                    <td>
                      {{ item.totalCost.toLocaleString('tr-TR', {
                        style: 'currency',
                        currency: 'TRY'}).replace(',00', '') }}
                    </td>
                  </tr>
                </tbody>
                <!-- <tfoot>
                  <tr>
                    <th>Total Revenue</th>
                    <th>{{ totalProcuctCount }}</th>
                    <th>{{ totalAmount }}</th>
                    <th>{{ totalAveragePrice }}</th>
                    <th>{{ totalCost }}</th>
                  </tr>
                </tfoot> -->
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const tarih = new Date();
const startDateGunlukFormat = tarih.toISOString().slice(0, 10);

const nextday = new Date();
nextday.setDate(nextday.getDate() + 1);

export default {
  name: 'ProductsByDate',
  data() {
    return {
      soldProducts: null,
      setProductDetails: null,
      waitingProducts: null,
      TarihBaslama: startDateGunlukFormat,
      TarihBitis: nextday.toISOString().slice(0, 10),
      search: '',
      loading: true,
      sortColumn: '', // Ekledik: Sıralama için kullanılacak sütunun başlangıç değeri boş
      sortDirection: 1, // Ekledik: Sıralama yönünün başlangıç değeri 1 (artan) olarak ayarlandı
    };
  },
  computed: {
    waitingProductsWithoutSet() {
      if (this.waitingProducts === null) {
        return null;
      }
      let temp = this.waitingProducts.slice();
      temp.forEach((element) => {
        const setArray = this.findSetProduct(element.productCode);
        if (setArray != null) {
          setArray.forEach((e) => {
            let found = false;
            temp.forEach((productObj) => {
              if (productObj.productCode === e.innerProductCode) {
                // eslint-disable-next-line no-param-reassign
                productObj.quantity += e.innerProductQuantity * element.quantity;
                found = true;
              }
              temp = temp.filter((item) => item !== element);
            });
            if (!found) {
              temp.push({ productCode: e.innerProductCode, productName: '-', quantity: element.quantity * e.innerProductQuantity });
            }
          });
        }
      });
      this.sortArray(temp);
      return temp;
    },
    searchFilter() {
      if (this.waitingProductsWithoutSet === null) {
        return null;
      }
      // eslint-disable-next-line max-len
      return this.waitingProductsWithoutSet.filter((item) => item.itemName.toLowerCase().includes(this.search.toLowerCase()));
    },
    totalAmount() {
      let count = 0;
      this.searchFilter.forEach((item) => {
        count += item.totalProductAmount;
      });
      return count.toLocaleString('tr-TR', {
        style: 'currency',
        currency: 'TRY',
      }).replace(',00', '');
    },
    totalProcuctCount() {
      let count = 0;
      this.searchFilter.forEach((item) => {
        count += item.totalProductCount;
      });
      return count;
    },
    totalAveragePrice() {
      let count = 0;
      this.searchFilter.forEach((item) => {
        count += item.averagePrice;
      });
      return count.toLocaleString('tr-TR', {
        style: 'currency',
        currency: 'TRY',
      });
    },
    totalCost() {
      let count = 0;
      this.searchFilter.forEach((item) => {
        count += item.totalCost;
      });
      return count.toLocaleString('tr-TR', {
        style: 'currency',
        currency: 'TRY',
      }).replace(',00', '');
    },
  },
  watch: {
    TarihBaslama() {
      this.getDailyProducts();
      this.loading = true;
    },
    TarihBitis() {
      this.getDailyProducts();
      this.loading = true;
    },
  },
  mounted() {
    this.getSetProductDetails();
    this.getDailyProducts();
  },
  methods: {
    getSetProductDetails() {
      this.axios
        .get(`${this.baseUrl}/dashboard/setProductDetails`, this.$store.getters.authHeader)
        .then((response) => {
          this.setProductDetails = response.data;
        }).catch(() => {
          this.$router.push('/');
        });
    },
    getDailyProducts() {
      this.axios
        .get(`${this.baseUrl}/public/platformOrderProductsGiro`, {
          params: { startDate: this.TarihBaslama, endDate: this.TarihBitis },
        }, this.$store.getters.authHeader)
        .then((response) => {
          this.waitingProducts = response.data;
          this.loading = false;
        }).catch(() => {
          this.$router.push('/');
        });
    },
    findSetProduct(productCode) {
      const temp = [];
      this.setProductDetails.forEach((element) => {
        if (element.setProductCode === productCode) {
          temp.push(element);
        }
      });
      return temp;
    },
    sortArray(array) {
      array.sort((x, y) => {
        if (x.quantity < y.quantity) {
          return 1;
        }
        if (x.quantity > y.quantity) {
          return -1;
        }
        return 0;
      });
    },
    sort(column) {
      if (this.sortColumn === column) {
        this.sortDirection *= -1;
      } else {
        this.sortColumn = column;
        this.sortDirection = 1;
      }

      this.waitingProducts.sort((a, b) => {
        if (a[column] < b[column]) {
          return -1 * this.sortDirection;
        } if (a[column] > b[column]) {
          return 1 * this.sortDirection;
        }
        return 0;
      });
    },
  },
};
</script>

<style>

.card-1{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  padding: 14px 80px 18px 36px;
  background-image: url(https://i.ibb.co/TKvR4LP/shopping-cart-2331970.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}

.card-2{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  padding: 14px 80px 18px 36px;
  background-image: url(https://i.ibb.co/vwx6Dr6/flip-10777073.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}
.card-3{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  padding: 14px 80px 18px 36px;
  background-image: url(https://i.ibb.co/MpJ7xdQ/money-bag-2953423.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}
.card-4{
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0,0,0,.08), 0 0 6px rgba(0,0,0,.05);
  padding: 14px 80px 18px 36px;
  background-image: url(https://i.ibb.co/RhDdNX5/calculator-4420303.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 80px;
}
</style>
