<template>
  <div>
    <div class="row">
      <Store />
    </div>
  </div>
</template>

<script>
import Store from '../components/SalesTracking/store.vue';

export default {
  components: { Store },
  mounted() {
    this.auth();
  },
  methods: {
    auth() {
      this.axios.get(`${this.baseUrl}/dashboard/auth`, this.$store.getters.authHeader)
        .catch(() => {
          this.$router.push('/');
        });
    },
  },
};
</script>

<style>

</style>
