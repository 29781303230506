<template>
  <div class="login">
    <div class="row login">
      <div class="col-md-12 mb-3">
        <img
          src="prslogo.png"
          alt="prslogo.png"
        >
      </div>
      <div class="col-md-12 mb-3">
        <input
          v-model="username"
          type="username"
          placeholder="Kullanıcı Adınızı Giriniz"
        >
      </div>
      <div class="col-md-12 mb-3">
        <input
          v-model="password"
          type="password"
          placeholder="Şifrenizi Giriniz"
        >
      </div>
      <div class="col-md-12">
        <button
          class="btn btn-success"
          @click="login"
        >
          Giriş Yap
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
    };
  },
  methods: {
    login() {
      this.axios.post(`${this.baseUrl}/login`, { username: this.username, password: this.password })
        .then((response) => {
          this.$root.sidebarVisible = true;
          localStorage.setItem('sidebarVisible', JSON.stringify(this.$root.sidebarVisible));
          this.$store.commit('setToken', { value: response.data.token });
          this.$router.push('/home');
        }).catch(() => {
          this.$router.push('/');
        });
    },
  },
};
</script>

<style>
img {
  width: 100%;
  max-width: 8000px;
  margin: 0 auto;
}

input {
  width: 100%;
  max-width: 300px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

button {
  width: 100%;
  max-width: 300px;
  background-color: #346aa8;
  color: white;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
