<template>
  <div>
    <div class="row">
      <h2>Hoş Geldiniz!</h2>
      <p>{{ bilgilendirmeMesaji }}</p>
      <p>{{ altbilgilendirmeMesaji }}</p>
    </div>
    <!-- <div class="row">
      <storeChart />
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Home',

  data() {
    return {
      bilgilendirmeMesaji: 'Bu sayfada herhangi bir işlem yapamazsınız. Lütfen üst taraftaki menüden işlem yapmak istediğiniz sayfayı seçiniz.',
      altbilgilendirmeMesaji: 'PROCSIN "Bilgi İşlem" departmanının bir çalışması devam ediyor. Bu çalışma tamamlandığında bu sayfada işlem yapabileceksiniz.',
      inventoryData: null,
    };
  },
  computed: {
    isLogin() {
      return this.$store.getters.authHeader != null;
    },
  },
  mounted() {
    this.auth();
  },
  methods: {
    auth() {
      this.axios.get(`${this.baseUrl}/dashboard/auth`, this.$store.getters.authHeader)
        .catch(() => {
          this.$router.push('/');
        });
    },
  },
};
</script>
