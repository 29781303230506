import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Shipment from '../views/Shipment.vue';
import Orders from '../views/Orders.vue';
import WaitingProducts from '../views/WaitingProducts.vue';
import ProductsByDate from '../views/ProductsByDate.vue';
import Giro from '../views/Giro.vue';
import Login from '../views/Login.vue';
import Stocks from '../views/Stocks.vue';
import Products from '../views/Products.vue';
import OnlineStocks from '../views/OnlineStocks.vue';
import AllStocks from '../views/AllStocks.vue';
import Platforms from '../views/Platforms.vue';
import Stores from '../views/Stores.vue';
import VideoExample from '../views/VideoExample.vue';
import VideoExampleMuted from '../views/VideoExampleMuted.vue';

const routes = [
  {
    path: '/platforms',
    name: 'Platforms',
    component: Platforms,
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/orders',
    name: 'Orders',
    component: Orders,
  },
  {
    path: '/shipment',
    name: 'Shipment',
    component: Shipment,
  },
  {
    path: '/waiting-products',
    name: 'WaitingProducts',
    component: WaitingProducts,
  },
  {
    path: '/products-by-date',
    name: 'ProductsByDate',
    component: ProductsByDate,
  },
  {
    path: '/onlineStocks',
    name: 'OnlineStocks',
    component: OnlineStocks,
  },
  {
    path: '/giro',
    name: 'Giro',
    component: Giro,
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/stocks',
    name: 'Stocks',
    component: Stocks,
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
  },
  {
    path: '/onlineStocks',
    name: 'OnlineStocks',
    component: OnlineStocks,
  },
  {
    path: '/allStocks',
    name: 'AllStocks',
    component: AllStocks,
  },
  {
    path: '/stores',
    name: 'Stores',
    component: Stores,
  },
  {
    path: '/video',
    name: 'VideoExample',
    component: VideoExample,
  },
  {
    path: '/videoMuted',
    name: 'VideoExampleMuted',
    component: VideoExampleMuted,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
