<template>
  <div>
    <div
      v-if="loading"
      class="tex-center"
    >
      <strong>Talep Edilen Veriler Yükleniyor...</strong> <br> <br>
      <div
        class="spinner-border ms-auto text-success"
        role="status"
        aria-hidden="true"
      />
    </div>
    <div v-else>
      <div class="row">
        <div
          v-if="productList"
          class="card"
        >
          <!-- Başlık -->
          <div class="row card-header mb-3">
            <div class="col">
              <h3 class="card-title">
                Ürünler Listesi
              </h3>
            </div>
          </div>
          <div class="row mb-2">
            <!-- Ürün Kullanım(a) Dışı / Açık -->
            <div class="col-md-auto">
              <span>
                <div class="form-check form-check-inline">
                  <input
                    id="inlineRadio1"
                    v-model="blockFilter"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="Kullanıma Açık"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio1"
                  >Kullanıma Açık</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="inlineRadio2"
                    v-model="blockFilter"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="Kullanım Dışı"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio2"
                  >Kullanım Dışı</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="inlineRadio3"
                    v-model="blockFilter"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    value="Tümü"
                    checked
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio3"
                  >Tümü</label>
                </div>
              </span>
            </div>
            <!-- Mağada Siparişe Kapalı / Açık -->
            <div class="col-md-auto">
              <span>
                <div class="form-check form-check-inline">
                  <input
                    id="inlineRadio4"
                    v-model="storeFilter"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    value="Mağaza Siparişi Açık"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio4"
                  >Mağaza Siparişi Açık</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="inlineRadio5"
                    v-model="storeFilter"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    value="Mağaza Siparişi Kapalı"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio5"
                  >Mağaza Siparişi Kapalı</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="inlineRadio6"
                    v-model="storeFilter"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions2"
                    value="Tümü"
                    checked
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio6"
                  >Tümü</label>
                </div>
              </span>
            </div>
            <!-- İnternete Kapalı / Açık -->
            <div class="col-md-auto">
              <span>
                <div class="form-check form-check-inline">
                  <input
                    id="inlineRadio7"
                    v-model="internetFilter"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions3"
                    value="İnternete Kapalı"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio7"
                  >İnternete Açık</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="inlineRadio8"
                    v-model="internetFilter"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions3"
                    value="İnternete Açık"
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio8"
                  >İnternete Kapalı</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    id="inlineRadio9"
                    v-model="internetFilter"
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions3"
                    value="Tümü"
                    checked
                  >
                  <label
                    class="form-check-label"
                    for="inlineRadio9"
                  >Tümü</label>
                </div>
              </span>
            </div>
          </div>
          <!-- Ürün Arama -->
          <div class="row mb-3">
            <div class="col-md-4">
              <div class="input-group">
                <input
                  v-model="search"
                  type="search"
                  placeholder="Ürün Araması Yapınız..."
                >
                <span class="input-group-text">{{ searchFilter.length }}</span>
              </div>
            </div>
          </div>
          <!-- Ürün Listesi -->
          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered table-hover table-striped">
                <thead class="text-white bg-danger thead">
                  <tr>
                    <th>Ürün Kodu</th>
                    <th>
                      <a
                        class="nav-link"
                        href="#"
                      >Ürün Adı</a>
                    </th>
                    <th>Aktif Barkod</th>
                    <!-- <th>Kullanım Dışı</th>
                    <th>Mağaza Siparişe Kapalı</th>
                    <th>İnternete Kapalı</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="product in searchFilter "
                    :key="product.itemCode"
                  >
                    <th>{{ product.itemCode }}</th>
                    <th>{{ product.itemDescription }}</th>
                    <th>{{ product.barcode }}</th>
                    <!-- <th>{{ product.isBlocked }}</th>
                    <th>{{ product.isStoreOrderClosed }}</th>
                    <th>{{ product.useInternet }}</th> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Products',
  data() {
    return {
      productList: null,
      blockFilter: 'Tümü',
      storeFilter: 'Tümü',
      internetFilter: 'Tümü',
      search: '',
      loading: true,
    };
  },
  computed: {
    searchFilter() {
      return this.productList.filter((item) => {
        const isBlockMatch = this.blockFilter === 'Tümü' || item.isBlocked === (this.blockFilter === 'Kullanım Dışı');
        const isStoreMatch = this.storeFilter === 'Tümü' || item.isStoreOrderClosed === (this.storeFilter === 'Mağaza Siparişi Kapalı');
        const isInternetMatch = this.internetFilter === 'Tümü' || item.useInternet === (this.internetFilter === 'İnternete Kapalı');
        const searchTerm = this.search.toLowerCase();
        const isMatch = item.itemDescription.toLowerCase().includes(searchTerm)
        || item.itemCode.toLowerCase().includes(searchTerm)
        || item.barcode.toLowerCase().includes(searchTerm);
        return isBlockMatch && isStoreMatch && isInternetMatch && isMatch;
      });
    },
  },

  mounted() {
    this.getProducts();
  },
  methods: {
    getProducts() {
      this.axios
        .get(`${this.baseUrl}/dashboard/productList`, this.$store.getters.authHeader)
        .then((response) => {
          this.productList = response.data;
          this.loading = false;
        }).catch(() => {
          this.$router.push('/');
        });
    },
  },
};
</script>
    <style>
    .form-check-input {
      margin-top: 0.3rem;
      margin-right: 0.5rem;
      padding: 8px;
    }
    </style>
