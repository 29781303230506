<template>
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <label for="startDatePicker">Başlangıç Tarihi:</label>
        <input
          id="startDatePicker"
          type="date"
          name="startDatePicker"
          value="2022-01-07"
          min="2021-06-01"
          max="2022-01-07"
        >
      </div>
      <div class="col-md-6">
        <label for="endDatePicker">Bitiş Tarihi:</label>
        <input
          id="endDatePicker"
          type="date"
          name="endDatePicker"
          value="2022-01-07"
          min="2021-06-01"
          max="2022-01-07"
        >
      </div>
    </div>
    <div class="clearfix" />
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Platform</th>
              <th>Adet</th>
              <th>Ciro</th>
              <th>Sepet Ort.</th>
              <th>Kar Oranı</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>TRENDYOL</th>
              <th>500</th>
              <th>12345₺</th>
              <th>100₺</th>
              <th>%73</th>
            </tr>
            <tr>
              <th>TSOFT</th>
              <th>500</th>
              <th>12345₺</th>
              <th>100₺</th>
              <th>%73</th>
            </tr>
            <tr>
              <th>HEPSIBURADA</th>
              <th>500</th>
              <th>12345₺</th>
              <th>100₺</th>
              <th>%73</th>
            </tr>
            <tr>
              <th>CICEKSEPETI</th>
              <th>500</th>
              <th>12345₺</th>
              <th>100₺</th>
              <th>%73</th>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>TOPLAM</th>
              <th>2000</th>
              <th>49380₺</th>
              <th>100₺</th>
              <th>%73</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
    <div class="clearfix" />
    <div class="row">
      <div class="col-md-12">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Ürün Adı</th>
              <th>Satış Adedi</th>
              <th>Ciro</th>
              <th>Ort. Fiyat</th>
              <th>Kar Oranı</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <th>Boom Butter Saç Bakım Yağı 190 ML</th>
              <th>12345₺</th>
              <th>500</th>
              <th>100₺</th>
              <th>%73</th>
            </tr>
            <tr>
              <th>2</th>
              <th>Üçlü Serum Paketi</th>
              <th>12345₺</th>
              <th>500</th>
              <th>100₺</th>
              <th>%73</th>
            </tr>
            <tr>
              <th>3</th>
              <th>Boom Butter Cilt Bakım Yağı 190 ML</th>
              <th>12345₺</th>
              <th>500</th>
              <th>100₺</th>
              <th>%73</th>
            </tr>
            <tr>
              <th>4</th>
              <th>Boom Butter Saç Bakım Şampuanı 400 ML</th>
              <th>12345₺</th>
              <th>500</th>
              <th>100₺</th>
              <th>%73</th>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th>#</th>
              <th>TOPLAM</th>
              <th>49380₺</th>
              <th>2000</th>
              <th>100₺</th>
              <th>%73</th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {

};
</script>

<style>

</style>
